import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/about-mobile.jpg"
import LuteinComplexTablet from "./../../images/about-tablet.jpg"
import LuteinComplexDesktop from "./../../images/about-desktop.jpg"
import LuteinComplexXL from "./../../images/about-desktop-xl.jpg"
import Feature1 from "./../../images/eu-fund.svg"
import Feature2 from "./../../images/vh-eu-fund.svg"


const AboutPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "about-en" }}>
    <Seo title="About Vita Herb" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>ABOUT VITA HERB</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="About Vita Herb"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-5 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            About Vita Herb
          </h1>
        </Col>
        <Col sm={12}>
          <p>
            We established Vita Herb because we believe the path to good health
            passes through nature. We started in 2002 as a family company,
            producing herbal tablets according to proven recipes, containing
            only selected herbs. After a number of studies, in 2004, in
            cooperation with Capsugel - France, we developed and introduced to
            the Bulgarian market, products manufactured using the special
            patented technology LICAPS ™. To date, Vita Herb is one of the
            leading manufacturers and importers of food supplements in Bulgaria.
          </p>
          <p>We at Vita Herb rely on 4 basic principles:</p>
          <ul>
            <li>
              Scientific support - The formulas are based on in-depth literature
              and research.
            </li>
            <li>
              Close relations with health professionals - partnerships with
              Bulgarian and foreign specialists from different sectors of
              medicine.
            </li>
            <li>
              Quality products - The efficiency, purity and safety of the
              products are the main priority of the brand.
            </li>
            <li>Understanding and striving to meet customer needs.</li>
          </ul>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Commitment to quality</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col sm={12} className="order-md-0">
          <p>
            The effectiveness of our products and their complete safety are
            ensured by adhering to the appropriate dosage, ensuring optimal
            bioactivity and supporting clinical evidence.
          </p>
          <p>
            Our innovative and exclusive (unique) formulas are developed from
            natural ingredients that meet the requirements of the new
            regulations on food additives (European Directive 2006/46 / EC).
          </p>
          <p>
            We work with certified French laboratories in the production of our
            products, which follow good pharmaceutical manufacturing practice
            and meet strict standards of hygiene and safety. Regular inspections
            during the production process guarantee the consumer the high
            quality and full traceability of the product.
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={{ span: 7, offset: 3 }} className="">
          <img srcSet={Feature2} alt="" className="mw-100 mx-auto" />
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={{ span: 7, offset: 3 }} className="">
          <img srcSet={Feature1} alt="" className="mw-100 mx-auto" />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPageEn
